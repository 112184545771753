import Image from "next/image";
import Link from "next/link";

export const Header = () => {
  return (
    <nav className="flex py-2 lg:py-6 lg:px-12 sticky top-0 z-30 bg-white w-full max-h-[80px] items-center">
      <Link href="/" className="w-full">
        <div className="flex lg:flex-col -mt-2 lg:-mt-3 lg:my-auto cursor-pointer justify-center w-full">
          <Image
            src="/landing/img/logo/logo-horiz-black.svg"
            className="hidden md:flex md:pt-2"
            alt="logo"
            width={190}
            height={46.82}
            layout="fixed"
          />
          <Image
            src="/landing/img/logo/logo-horiz-black.svg"
            className="flex md:hidden w-1/3 self-center pb-2 pt-4"
            alt="logo"
            width={64}
            height={32}
            layout="fixed"
          />
        </div>
      </Link>
    </nav>
  );
};
